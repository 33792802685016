const palette = {
    button1: '#6485CF',
    button2: '#2B478D',
    main1: '#C3DEF7',
    main2: '#405EAB',
    font: '#282828',
    grey: '#282828',
    white: '#FFFCFA',
};

export default palette;
