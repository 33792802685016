import React from 'react';
import { createSvgIcon } from '@mui/material';

const PhotoIcon = createSvgIcon(
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
    >
        <path
            d='M15 8H15.01M3 6C3 5.20435 3.31607 4.44129 3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3H18C18.7956 3 19.5587 3.31607 20.1213 3.87868C20.6839 4.44129 21 5.20435 21 6V18C21 18.7956 20.6839 19.5587 20.1213 20.1213C19.5587 20.6839 18.7956 21 18 21H6C5.20435 21 4.44129 20.6839 3.87868 20.1213C3.31607 19.5587 3 18.7956 3 18V6Z'
            stroke='#5A77B7'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />

        <path
            d='M3 16L8 11C8.928 10.107 10.072 10.107 11 11L16 16'
            stroke='#5A77B7'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />

        <path
            d='M14 14L15 13C15.928 12.107 17.072 12.107 18 13L21 16'
            stroke='#5A77B7'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default PhotoIcon;
